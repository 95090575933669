<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="20" class="top_l">
					<el-col :span="5" :xs="24"><el-input v-model="search" clearable placeholder="请输入描述查询"></el-input></el-col>
					<el-col :span="2" :xs="6"><el-button type="primary" @click="Csearch">搜索</el-button></el-col>
					<el-col :span="10" :xs="18">
						<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
							<el-button
								v-if="(item.parentId == twoId && item.permission.includes('add')) || (item.parentId == twoId && item.permission.includes('batch'))"
								:key="index"
								type="primary"
								@click="update(item.permission.slice(5))"
							>
								{{ item.name }}
							</el-button>
						</template>
					</el-col>
				</el-row>
				<el-table v-loading="serloading" :data="tableData" border stripe :header-cell-style="{ background: '#eef1f6' }" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="50" align="center"></el-table-column>
					<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
					<el-table-column prop="name" label="角色名"></el-table-column>
					<el-table-column label="是否可用">
						<template v-slot="scope">
							<el-switch
								style="display: block"
								disabled
								v-model="scope.row.available"
								active-color="#13ce66"
								inactive-color="#ff4949"
								active-text="是"
								inactive-text="否"
							></el-switch>
						</template>
					</el-table-column>
					<el-table-column prop="description" label="描述"></el-table-column>
					<el-table-column prop="createTime" label="创建时间"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间"></el-table-column>
					<el-table-column label="操作" width="400" align="center">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="handleSee(scope.$index, scope.row)">查看</el-button>
							<template v-for="(item, index) in this.$TOOL.data.get('USER_INFO').sysResourcesEntities">
								<el-button
									v-if="item.parentId == twoId && !item.permission.includes('batch') && !item.permission.includes('add')"
									:key="index"
									size="small"
									type="primary"
									plain
									@click="update(item.permission.slice(5), scope.row)"
								>
									{{ item.name }}
								</el-button>
							</template>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
				<!-- 添加角色 -->
				<el-dialog title="添加角色" v-model="dialogAdd" :fullscreen="fullscreenshow" width="50%">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="80px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="角色名" prop="name"><el-input placeholder="请输入角色名" v-model="ruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="描述" prop="description"><el-input placeholder="请输入描述" v-model="ruleForm.description"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 查看详情 -->
				<el-dialog title="角色详情" v-model="dialogDetails" :fullscreen="fullscreenshow" width="50%">
					<el-descriptions border :column="2">
						<el-descriptions-item label="ID">{{ details.id }}</el-descriptions-item>
						<el-descriptions-item label="角色名">{{ details.name }}</el-descriptions-item>
						<el-descriptions-item label="描述">{{ details.description }}</el-descriptions-item>
						<el-descriptions-item label="创建时间">{{ details.createTime }}</el-descriptions-item>
						<el-descriptions-item label="更新时间">{{ details.updateTime }}</el-descriptions-item>
						<el-descriptions-item label="是否可用">{{ details.available }}</el-descriptions-item>
					</el-descriptions>
					<template #footer>
						<span class="dialog-footer"><el-button @click="dialogDetails = false">取 消</el-button></span>
					</template>
				</el-dialog>
				<!-- 修改角色 -->
				<el-dialog title="修改角色" v-model="dialogEdit" :fullscreen="fullscreenshow" width="50%">
					<el-form :model="EditruleForm" :rules="Editrules" ref="EditruleFormRef" label-width="80px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="角色名" prop="name"><el-input v-model="EditruleForm.name"></el-input></el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="描述" prop="description"><el-input v-model="EditruleForm.description"></el-input></el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<template #footer>
						<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
						<el-button @click="dialogEdit = false">取消</el-button>
					</template>
				</el-dialog>
				<!-- 分配角色权限 -->
				<el-dialog title="分配角色资源" v-model="assignuserroles" :fullscreen="fullscreenshow" top="4vh" width="50%">
					<el-row :gutter="10">
						<el-col class="eol" :span="24" v-for="(item, index) in ssignusData" :key="index">
							<el-checkbox v-model="item.checked" :checked="item.checked" @change="handleCheckAllChange(index, $event)">{{ item.name }}</el-checkbox>
							<div v-for="(partition, partitionIndex) in item.nodes" :key="partitionIndex" style="margin: 15px 0 25px 20px;">
								<el-checkbox
									v-model="partition.checked"
									:checked="partition.checked"
									@change="handleCheckedCountryAllChange(index, partitionIndex, $event)"
									:key="partitionIndex"
								>
									{{ partition.name }}
								</el-checkbox>
								<div style="margin: 15px 0 15px 20px;">
									<el-checkbox
										v-for="country in partition.nodes"
										:checked="country.checked"
										v-model="country.checked"
										@change="handleCheckedCountryChange(index, partitionIndex, country.id, $event)"
										:label="country"
										:key="country.id"
									>
										{{ country.name }}
									</el-checkbox>
								</div>
							</div>
						</el-col>
					</el-row>
					<template #footer>
						<el-button type="primary" @click="classdata">确定</el-button>
						<el-button @click="assignuserroles = false">取消</el-button>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'roles',
	data() {
		return {
			// 分配角色资源
			assignuserroles: false,
			resourcesId: [],
			roleId: '',
			ssignusData: [],

			details: [],
			dialogDetails: false,
			multipleSelection: [],
			fullscreenshow: false,
			serloading: false,
			totals: null,
			tableData: [],
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			twoId: null,
			search: '',
			// 添加角色
			dialogAdd: false,
			ruleForm: {
				name: '',
				description: ''
			},
			rules: {
				name: [{ required: true, message: '请输入角色名', trigger: 'blur' }],
				description: [{ required: true, message: '请输入描述', trigger: 'blur' }]
			},
			// 修改角色
			dialogEdit: false,
			EditruleForm: {
				name: '',
				description: '',
				id: ''
			},
			Editrules: {
				name: [{ required: true, message: '请输入角色名', trigger: 'blur' }],
				description: [{ required: true, message: '请输入描述', trigger: 'blur' }]
			}
		};
	},
	created() {
		// 二级目录ID
		this.twoId = this.$route.query.Id;
		// 获取角色列表
		this.role();
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}
	},
	methods: {
		// 获取角色列表
		role() {
			this.serloading = true;
			this.$HTTP.post(
				'roles/list',
				{
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					keywords: this.search
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 模糊查询
		Csearch() {
			this.currentPage = 1;
			// 获取角色列表
			this.role();
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		update(methodsNums, row) {
			//触发这个参数的方法
			this[methodsNums](row);
		},
		// 添加角色
		add() {
			this.dialogAdd = true;
		},
		// 添加角色
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'roles/selectByName',
						{
							name: this.ruleForm.name
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.data == true) {
									this.$message.error('角色名重复');
								} else {
									this.$HTTP.post(
										'roles/add',
										{
											name: this.ruleForm.name,
											description: this.ruleForm.description
										},
										response => {
											if (response.status >= 200 && response.status < 300) {
												if (response.data.code == 0) {
													this.dialogAdd = false;
													this.$message.success(response.data.msg);
													this.resetClick(formName);
													// 获取用户列表
													this.role();
												} else {
													this.$message.error(response.data.msg);
												}
											} else {
												console.log(response.message);
											}
										}
									);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 多选
		handleSelectionChange(val) {
			this.multipleSelection = [];
			for (var i = 0; i < val.length; i++) {
				this.multipleSelection.push(val[i].id);
			}
		},
		// 批量删除
		batchDelete() {
			if (this.multipleSelection == '') {
				return this.$message.error('请选择删除内容');
			}
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$HTTP.post(
						'roles/remove',
						{
							ids: this.multipleSelection
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取用户列表
									this.role();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		// 查看
		handleSee(index, row) {
			this.dialogDetails = true;
			this.$HTTP.post('roles/get/' + row.id + '', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					this.details = response.data.data;
				} else {
					console.log(response.message);
				}
			});
		},
		// 修改
		edit(row) {
			this.EditruleForm.name = row.name;
			this.EditruleForm.description = row.description;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		// 修改角色
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'roles/edit',
						{
							name: this.EditruleForm.name,
							description: this.EditruleForm.description,
							id: this.EditruleForm.id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取用户列表
									this.role();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		delete(row) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					var id = [];
					id.push(row.id);
					this.$HTTP.post(
						'roles/remove',
						{
							ids: id
						},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.$message.success(response.data.msg);
									// 获取角色列表
									this.role();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		//分配用户角色
		allotResource(row) {
			this.assignuserroles = true;
			this.roleId = row.id;
			this.$HTTP.post('resources/resourcesWithSelected/' + row.id + '', {}, response => {
				if (response.status >= 200 && response.status < 300) {
					this.ssignusData = response.data.data;
				} else {
					console.log(response.message);
				}
			});
		},
		handleCheckAllChange(index, e) {
			//一级change事件
			this.ssignusData[index].checked = e;
			if (e == true) {
				var childrenArray = this.ssignusData[index].nodes;
				for (var j = 0, len1 = childrenArray.length; j < len1; j++) {
					childrenArray[j].checked = e;
					if (childrenArray[j].nodes != undefined) {
						for (var k = 0, len2 = childrenArray[j].nodes.length; k < len2; k++) {
							childrenArray[j].nodes[k].checked = e;
						}
					}
				}
			} else {
				let childrenArray = this.ssignusData[index].nodes;
				for (let j = 0, len1 = childrenArray.length; j < len1; j++) {
					childrenArray[j].checked = e;
					if (childrenArray[j].nodes != undefined) {
						for (let k = 0, len2 = childrenArray[j].nodes.length; k < len2; k++) {
							childrenArray[j].nodes[k].checked = e;
						}
					}
				}
			}
		},
		handleCheckedCountryAllChange(index, partitionIndex, e) {
			//二级change事件
			this.ssignusData[index].nodes[partitionIndex].checked = e; //二级勾选后，子级全部勾选或者取消
			var childrenArray = this.ssignusData[index].nodes[partitionIndex].nodes;
			if (childrenArray) for (var i = 0, len = childrenArray.length; i < len; i++) childrenArray[i].checked = e;
			this.getIsCheckAll(index);
		},
		handleCheckedCountryChange(index, partitionIndex, sonId, e) {
			//三级change事件
			var childrenArray = this.ssignusData[index].nodes[partitionIndex].nodes;
			var tickCount = 0,
				unTickCount = 0,
				len = childrenArray.length;
			for (var i = 0; i < len; i++) {
				if (sonId == childrenArray[i].id) childrenArray[i].checked = e;
				if (childrenArray[i].checked == true) tickCount++;
				if (childrenArray[i].checked == false) unTickCount++;
			}
			if (tickCount == len) {
				//三级级全勾选
				this.ssignusData[index].nodes[partitionIndex].checked = true;
			} else if (unTickCount == len) {
				//三级级全不勾选
				this.ssignusData[index].nodes[partitionIndex].checked = false;
			} else {
				this.ssignusData[index].nodes[partitionIndex].checked = true;
			}

			this.getIsCheckAll(index);
		},
		getIsCheckAll(index) {
			var tickCount = 0,
				unTickCount = 0,
				ArrLength = this.ssignusData[index].nodes.length;
			for (var j = 0; j < ArrLength; j++) {
				//全选checkbox状态
				if (this.ssignusData[index].nodes[j].checked == true) tickCount++;
				if (this.ssignusData[index].nodes[j].checked == false) unTickCount++;
			}
			if (tickCount == ArrLength) {
				//二级全勾选
				this.ssignusData[index].checked = true;
			} else if (unTickCount == ArrLength) {
				//二级全不勾选
				this.ssignusData[index].checked = false;
			} else {
				this.ssignusData[index].checked = true;
			}
		},
		classdata() {
			this.resourcesId = [];

			for (var i = 0; i < this.ssignusData.length; i++) {
				if (this.ssignusData[i].checked == true) {
					this.resourcesId.push(this.ssignusData[i].id);
				}
				for (var j = 0; j < this.ssignusData[i].nodes.length; j++) {
					if (this.ssignusData[i].nodes[j].checked == true) {
						this.resourcesId.push(this.ssignusData[i].nodes[j].id);
					}
					if (this.ssignusData[i].nodes[j].nodes != undefined) {
						for (var k = 0; k < this.ssignusData[i].nodes[j].nodes.length; k++) {
							if (this.ssignusData[i].nodes[j].nodes[k].checked == true) {
								this.resourcesId.push(this.ssignusData[i].nodes[j].nodes[k].id);
							}
						}
					}
				}
			}

			if (this.resourcesId == '') {
				return this.$message.error('请选择权限');
			}
			this.$HTTP.post(
				'roles/saveRoleResources',
				{
					resourcesId: this.resourcesId.join(','),
					roleId: this.roleId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							this.assignuserroles = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取角色列表
			this.role();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取角色列表
			this.role();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
